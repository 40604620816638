import { useEffect, useState } from 'react';
import { Box, Typography, MenuItem } from '@mui/material';
import HookForm from '../../HookForm';
import { createYupResolver } from '../../HookForm/yupValidations';
import {
  formatPhoneNumberE164,
  formatPhoneNumber,
} from '../../../services/textFormatting';
import { voiceOptions } from './utils';

const PHONE = 'number';
const DIGITS = 'digits';
const MESSAGE = 'message';
const VOICE = 'voice';

export const Dial = ({
  handleMutateComponentListByParametersInUI,
  id,
  parameters,
  doSearchEmptyInputs,
  getUpdatedInputParams,
}) => {
  const [inputParameters, setInputParameters] = useState(
    getUpdatedInputParams(id)?.parameters || parameters
  );
  const defaultValues = {
    [PHONE]: formatPhoneNumber(inputParameters?.[PHONE] || ''),
    [DIGITS]: inputParameters?.[DIGITS] || '',
    [MESSAGE]: inputParameters?.[MESSAGE] || '',
    [VOICE]: inputParameters?.[VOICE] || '',
  };

  const useFormProps = { defaultValues, resolver: createYupResolver(defaultValues) };

  const handleChange = (form) => {
    const value =
      form.target.name === PHONE
        ? formatPhoneNumberE164(form.target.value)
        : form.target.value;

    setInputParameters({
      ...inputParameters,
      [form.target.name]: value,
    });
    handleMutateComponentListByParametersInUI({ [form.target.name]: value }, id);
  };

  useEffect(() => {
    const neededParams =
      inputParameters && Object.keys(inputParameters).length > 0
        ? inputParameters
        : defaultValues;
    handleMutateComponentListByParametersInUI(neededParams, id);
  }, []);

  return (
    <Box>
      <HookForm onChange={handleChange} useFormProps={useFormProps}>
        <Typography fontSize={18} fontWeight={400} mt={2}>
          This is where the phone will ring when customers dial your tracking number.
        </Typography>
        <Typography fontSize={16} fontWeight={500} mt={2} mb={1}>
          Forward Calls To
        </Typography>

        <HookForm.PhoneNumberField
          sx={{ width: '300px' }}
          name={PHONE}
          label="Phone Number"
          showError={doSearchEmptyInputs && !inputParameters.number}
        />
        <br />
        <HookForm.TextField
          sx={{ width: '300px', marginTop: '20px' }}
          name={DIGITS}
          label="Extension"
          placeholder="Extension"
          error={doSearchEmptyInputs && !inputParameters.digits}
        />
        <br />
        <HookForm.Select
          sx={{ width: '300px', marginTop: '20px' }}
          name={VOICE}
          value={inputParameters.voice}
          label="Please choose voice"
          onChange={handleChange}
        >
          {voiceOptions.map(({ operator, label }) => (
            <MenuItem key={operator} value={operator}>
              {label}
            </MenuItem>
          ))}
        </HookForm.Select>
        <br />
        <HookForm.TextField
          sx={{ width: '300px', marginTop: '20px' }}
          name={MESSAGE}
          label="Message"
          multiline
          placeholder="Type your message here"
        />
      </HookForm>
    </Box>
  );
};
